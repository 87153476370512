<template>
    <div class="p-grid" :key="componentKey">
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="machines" sortField="id" :sortOrder="-1" :paginator="true" class="p-datatable-customers" :rows="10" dataKey="company_id" :rowHover="true" 
                        :filters="filters1" :loading="loading1">
					<template #header>
						<div class="table-header">
									List of Machines
									<Button label="New" icon="pi pi-plus" class="p-button-success p-input-icon-left p-mr-2 p-mb*2 m-auto-left" @click="openNewCompanyModal" />
									<span class="p-input-icon-left">
										<i class="pi pi-search" />
										<InputText v-model="filters1['global']" placeholder="Global Search" />
									</span>	
						</div>
					</template>
					<!-- <template #empty>
						No machines found.
					</template> -->
					<template #loading>
						Loading machines data. Please wait.
					</template>
					<Column field="id" header="ID" :sortable="true" sortField="id">
						<template #body="slotProps" index="id">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="machine_group" header="Machine group" :sortable="true" sortField="machine_group">
						<template #body="slotProps" index="machine_group">
							{{slotProps.data.machine_group}}
						</template>
					</Column>
					<Column header="Type" :sortable="true" sortField="machine_type" filterField="machine_type">
						<template #body="slotProps">
							<span style="margin-left: .5em; vertical-align: middle" class="image-text">{{slotProps.data.machine_type}}</span>
						</template>
					</Column>
					<Column header="Brand" :sortable="true" sortField="machine_brand" filterField="machine_brand">
						<template #body="slotProps">
							<span v-tooltip="slotProps.data.machine_brand" style="margin-left: .5em; vertical-align: middle" class="image-text">{{slotProps.data.machine_brand}}</span>
						</template>
					</Column>
					<Column field="machine_model" header="Model" :sortable="true" sortField="machine_model">
						<template #body="slotProps">
							<span>{{slotProps.data.machine_model}}</span>
						</template>
					</Column>
					<Column field="region" header="Region" :sortable="true" sortField="region">
						<template #body="slotProps">
							<span>{{slotProps.data.region}}</span>
						</template>
					</Column>
					<Column field="district" header="District" :sortable="true" sortField="district">
						<template #body="slotProps">
							<span :class="' '">{{slotProps.data.district}}</span>
						</template>
					</Column>
					<Column field="company_name" header="Company name" :sortable="true" sortField="company_name">
						<template #body="slotProps">
							<span :class="'' ">{{slotProps.data.company_name}}</span>
						</template>
					</Column>
					<Column field="count" header="Count" :sortable="true" sortField="count">
						<template #body="slotProps">
							<span :class="'' ">{{slotProps.data.count}}</span>
						</template>
					</Column>
					<Column headerStyle="width: 8rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
						<template #body="slotProps">
							<Button type="button" icon="pi pi-pencil" class="p-button-rounded p-button-success" @click="openModal(slotProps.data)"></Button>
						</template>
					</Column>
					<Column headerStyle="width: 8rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
						<template #body="slotProps">
							<Button type="button" icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="openDeleteModal(slotProps.data)"></Button>
						</template>
					</Column>
				</DataTable>

                <Dialog header="Dialog" v-model:visible="displayEditModal" :style="{width: '30vw'}" :modal="true">
					<div class="p-col-12">
						<div class="card">
							<h5>Edit machine information</h5>
							<div class="p-fluid p-formgrid p-grid">
								<div class="p-field p-col-12 p-md-6">
									<label for="m_group">Group</label>
									<InputText id="m_group" type="text" v-if="!displayNewModal" v-model="selectedMachine.machine_group"/>
									<InputText id="m_group" type="text" v-else v-model="newMachine.machine_group"/>
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="m_type">Type</label>
									<InputText id="m_type" type="text" v-if="!displayNewModal" v-model="selectedMachine.machine_type"/>
									<InputText id="m_type" type="text" v-else v-model="newMachine.machine_type"/>
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="m_brand">Brand</label>
									<InputText id="m_brand" rows="4" v-if="!displayNewModal" v-model="selectedMachine.machine_brand"/>
									<InputText id="m_brand" rows="4" v-else v-model="newMachine.machine_brand"/>
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="m_model">Model</label>
									<InputText id="m_model" type="text" v-if="!displayNewModal" v-model="selectedMachine.machine_model"/>
									<InputText id="m_model" type="text" v-else v-model="newMachine.machine_model"/>

								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="c_region">Region</label>
									<InputText id="c_region" v-if="!displayNewModal" v-model="selectedMachine.region"></InputText>
									<InputText id="c_region" v-else v-model="newMachine.region"></InputText>
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="c_district">District</label>
									<InputText id="c_district" v-if="!displayNewModal" v-model="selectedMachine.district"></InputText>
									<InputText id="c_district" v-else v-model="newMachine.district"></InputText>
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="c_name">Company Name</label>
									<InputText id="c_name" v-if="!displayNewModal" v-model="selectedMachine.company_name"></InputText>
									<InputText id="c_name" v-else v-model="newMachine.company_name"></InputText>
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="m_count">Count</label>
									<InputText id="m_count" v-if="!displayNewModal" v-model="selectedMachine.count"></InputText>
									<InputText id="m_count" v-else v-model="newMachine.count"></InputText>
								</div>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Save" @click="insertMachine" icon="pi pi-check" class="p-button-text"/>
						<Button label="Dismiss" @click="closeModal" icon="pi pi-times" class="p-button-text"/>
					</template>
				</Dialog>

				<Dialog header="Dialog" v-model:visible="displayDeleteModal" :style="{width: '30vw'}" :modal="true">
					<p>
						Are you sure to delete?
					</p>
					<template #footer>
						<Button label="Yes" @click="deleteMachine" icon="pi pi-check" class="p-button-text"/>
						<Button label="No" @click="closeDeleteModal" icon="pi pi-times" class="p-button-text"/>
					</template>
				</Dialog>
				
			</div>
		</div>
	</div>
</template>

<script>

    import MachineService from "../service/MachinesService";
	
	export default {
		data() {
			return {
                machines:[],
				selectedMachine: {},
				filters1: {},
				filters2: {},
				loading1: true,
				loading2: true,
				products: null,
				expandedRows: [],
                displayEditModal: false,
				displayNewModal: false,
				displayDeleteModal: false,
				componentKey: 0,
				newMachine: { machine_group:'', machine_model:'', machine_brand:'', machine_type:'',region:'', disctrict:'', company_name:'', count:''}
			}
		},
		machineService: null,
        created() {
            this.machineService = new MachineService();
		},
		mounted() {
			this.onMounted()
		},
		methods: {
			onMounted() {
				this.machineService.getMachinesAll().then(data => {
					if (data.isLogged){
						this.machines = data.fetch_data;
						this.loading1 = false;
						this.loading2 = false;		
					}
					else{
						this.$router.push('Login')
					}
				});
			},
			forceRerender(){
				this.componentKey +=1;
			},
            openModal: function(rowId){
				this.selectedMachine = rowId;
				console.log(rowId);
                this.displayEditModal = true;
			},
            closeModal(){
                this.displayEditModal = false;
				this.selectedMachine = {};
				if(this.displayNewModal == true){
					this.displayNewModal = false;
					this.newMachine = null;
				}

            },
			openDeleteModal: function(rowId){
				
				this.selectedMachine = rowId;
				this.displayDeleteModal = true;
			},
			closeDeleteModal(){
				this.displayDeleteModal = false;
			},
			openNewCompanyModal(){
				this.displayEditModal = true;
				this.displayNewModal = true;
			},
			deleteMachine(){
				this.machineService.deleteMachine(this.selectedMachine)
					.then(response => {
						console.log(response)
						return response
					})
					.then(response => {					
						this.closeDeleteModal();
						return response
					})
					.then(response => {
						this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
						return response
					})
					.then(response => {
						this.onMounted()
						return response
					}) 
					.catch(error => {
						console.log(error)
					})
			},
			insertMachine(){
				if(this.displayNewModal){
					this.machineService.insertNewMachine(this.newMachine)
					this.forceRerender();
					this.closeModal();
				} else {
					this.machineService.updateMachine(this.selectedMachine)
					this.closeModal();
				}
			}

		}
	}
</script>

<style scoped lang="scss">
::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
        overflow:hidden;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>
