import axios from 'axios'
axios.defaults.withCredentials = true;


export default class MachineService {

  getMachinesAll() {

    return axios.get('https://advantex.uz/pmdb/machines').then(res => res.data);
  }

  insertNewMachine(newMachine) {
    // console.log(newMachine);
    var data = JSON.stringify({
      "array": {
        "machine_group": newMachine.machine_group,
        "machine_type": newMachine.machine_type,
        "machine_brand": newMachine.machine_brand,
        "machine_model": newMachine.machine_model,
        "region": newMachine.region,
        "district": newMachine.district,
        "company_name": newMachine.company_name,
        "count": newMachine.count
      }
    });

    var config = {
      method: 'post',
      url: 'https://advantex.uz/pmdb/insert_machines_info',
      headers: {
        'Content-Type': 'application/json',
        // 'Cookie': 'ACookieAvailableCrossSite; PHPSESSID=217d21e7c30af53f84a38963a2211497e47ffc51'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  updateMachine(updatingMachine) {
    var data = JSON.stringify({
      "array": {
        "machine_group": updatingMachine.machine_group,
        "machine_type": updatingMachine.machine_type,
        "machine_brand": updatingMachine.machine_brand,
        "machine_model": updatingMachine.machine_model,
        "region": updatingMachine.region,
        "district": updatingMachine.district,
        "company_name": updatingMachine.company_name,
        "count": updatingMachine.count
      },
      "id": updatingMachine.id
    });

    var config = {
      method: 'post',
      url: 'https://advantex.uz/pmdb/update_machines_info',
      headers: {
        'Content-Type': 'application/json',
        // 'Cookie': 'ACookieAvailableCrossSite; PHPSESSID=5607593c72454c99370d4feb019a51933ae9e6cd'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  deleteMachine(deletingMachine) {
    // var axios = require('axios');
    var data = JSON.stringify({
      "id": deletingMachine.id
    });

    var config = {
      method: 'post',
      url: 'https://advantex.uz/pmdb/deleteMachines',
      headers: {
        'Content-Type': 'application/json',
        // 'Cookie': 'PHPSESSID=6fe53178f9be9c2058ccbf365760215a3385f122'
      },
      data: data
    };

    return axios(config)
    // .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });

  }


  // getMachineById(id){
  //   return axios.get('https://advantex.uz/pmdb/get_company_info',{  
  //     params: {
  //       id: id
  //     }
  //   }).then(res => res.data);
  // }


}  
